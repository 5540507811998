import React, { useEffect } from "react";
import Layout from "../components/layout";

const Form = () => {
  useEffect(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;

    s.src = "https://yastatic.net/s3/frontend/forms/_/embed.js";

    const h = document.getElementById("yandexform");
    h.parentNode.insertBefore(s, h);
  }, []);
/*   const form = false; */
  useEffect(() => {
    const iframe = document.createElement("iframe");

    iframe.height = "100%";
    iframe.width = "auto";
    iframe.src = "https://forms.yandex.ru/u/629d5d5356736369edfb6a0d/?iframe=1";

    const diviframe = document.getElementById("iframeform");
    diviframe.parentNode.insertBefore(iframe, diviframe);

  }, []);

  return (
    <Layout>
      <div className="container">
        <h1 className="text-center mt-60">Форма для заказа</h1>
        <div className="jivoACenter">
          Заполните форму или{" "}
          <a className="jivoBtn" href="javascript:jivo_api.open()">
            сделайте заказ онлайн
          </a>
          <br />
        </div>
        <div className="form__ya">
          <div id="yandexform"></div>
          <div id="iframeform"></div>
        </div>
      </div>
    </Layout>
  );
};

export default Form;
